import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DOMPurify from 'dompurify';

const About = () => {
    const slug = 'about'; // Set slug directly for the static about page
    const [page, setPage] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPageData = async () => {
            try {
                const response = await axios.get(`http://127.0.0.1/blog/index.php/blog/pages/${slug}`);
                console.log('API Response:', response.data); // Debug: Check response data
                setPage(response.data);
            } catch (err) {
                console.error('Error fetching the post:', err);
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchPageData();
    }, [slug]);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error fetching page data.</div>;
    if (!page) return <div>No page data found.</div>;

    const createMarkup = (html) => {
        return { __html: DOMPurify.sanitize(html) };
    };


    return (
        <div className="container">
            <div className="row">
                <div className="col-md-2 col-xs-12">
                    <div className="share">
                        <p>Share</p>
                        <ul>
                            <li>
                                <a target="_blank" href="https://twitter.com/home?status=http%3A//www.wowthemes.net">
                                    <svg className="svgIcon-use" width="29" height="29" viewBox="0 0 29 29">
                                        <path d="M21.967 11.8c.018 5.93-4.607 11.18-11.177 11.18-2.172 0-4.25-.62-6.047-1.76l-.268.422-.038.5.186.013.168.012c.3.02.44.032.6.046 2.06-.026 3.95-.686 5.49-1.86l1.12-.85-1.4-.048c-1.57-.055-2.92-1.08-3.36-2.51l-.48.146-.05.5c.22.03.48.05.75.08.48-.02.87-.07 1.25-.15l2.33-.49-2.32-.49c-1.68-.35-2.91-1.83-2.91-3.55 0-.05 0-.01-.01.03l-.49-.1-.25.44c.63.36 1.35.57 2.07.58l1.7.04L7.4 13c-.978-.662-1.59-1.79-1.618-3.047a4.08 4.08 0 0 1 .524-1.8l-.825.07a12.188 12.188 0 0 0 8.81 4.515l.59.033-.06-.59v-.02c-.05-.43-.06-.63-.06-.87a3.617 3.617 0 0 1 6.27-2.45l.2.21.28-.06c1.01-.22 1.94-.59 2.73-1.09l-.75-.56c-.1.36-.04.89.12 1.36.23.68.58 1.13 1.17.85l-.21-.45-.42-.27c-.52.8-1.17 1.48-1.92 2L22 11l.016.28c.013.2.014.35 0 .52v.04zm.998.038c.018-.22.017-.417 0-.66l-.498.034.284.41a8.183 8.183 0 0 0 2.2-2.267l.97-1.48-1.6.755c.17-.08.3-.02.34.03a.914.914 0 0 1-.13-.292c-.1-.297-.13-.64-.1-.766l.36-1.254-1.1.695c-.69.438-1.51.764-2.41.963l.48.15a4.574 4.574 0 0 0-3.38-1.484 4.616 4.616 0 0 0-4.61 4.613c0 .29.02.51.08.984l.01.02.5-.06.03-.5c-3.17-.18-6.1-1.7-8.08-4.15l-.48-.56-.36.64c-.39.69-.62 1.48-.65 2.28.04 1.61.81 3.04 2.06 3.88l.3-.92c-.55-.02-1.11-.17-1.6-.45l-.59-.34-.14.67c-.02.08-.02.16 0 .24-.01 2.12 1.55 4.01 3.69 4.46l.1-.49-.1-.49c-.33.07-.67.12-1.03.14-.18-.02-.43-.05-.64-.07l-.76-.09.23.73c.57 1.84 2.29 3.14 4.28 3.21l-.28-.89a8.252 8.252 0 0 1-4.85 1.66c-.12-.01-.26-.02-.56-.05l-.17-.01-.18-.01L2.53 21l1.694 1.07a12.233 12.233 0 0 0 6.58 1.917c7.156 0 12.2-5.73 12.18-12.18l-.002.04z"></path>
                                    </svg>
                                </a>
                            </li>
                            <li>
                                <a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=http%3A//www.wowthemes.net">
                                    <svg className="svgIcon-use" width="29" height="29" viewBox="0 0 29 29">
                                        <path d="M16.39 23.61v-5.808h1.846a.55.55 0 0 0 .546-.48l.36-2.797a.551.551 0 0 0-.547-.62H16.39V12.67c0-.67.12-.813.828-.813h1.474a.55.55 0 0 0 .55-.55V8.803a.55.55 0 0 0-.477-.545c-.436-.06-1.36-.116-2.22-.116-2.5 0-4.13 1.62-4.13 4.248v1.513H10.56a.551.551 0 0 0-.55.55v2.797c0 .304.248.55.55.55h1.855v5.76c-4.172-.96-7.215-4.7-7.215-9.1 0-5.17 4.17-9.36 9.31-9.36 5.14 0 9.31 4.19 9.31 9.36 0 4.48-3.155 8.27-7.43 9.15M14.51 4C8.76 4 4.1 8.684 4.1 14.46c0 5.162 3.75 9.523 8.778 10.32a.55.55 0 0 0 .637-.543v-6.985a.551.551 0 0 0-.55-.55H11.11v-1.697h1.855a.55.55 0 0 0 .55-.55v-2.063a3.17 3.17 0 0 1 3.18-3.183c.578 0 1.235.03 1.714.06v1.756h-.923c-1.362 0-1.93.588-1.93 1.915v2.52a.55.55 0 0 0 .55.55h2.007l-.255 1.695h-1.752a.55.55 0 0 0-.55.55v6.872a.55.55 0 0 0 .637.543C21.45 23.882 25.9 19.49 25.9 14.46 25.9 8.684 21.24 4 15.49 4"></path>
                                    </svg>
                                </a>
                            </li>
                            <li>
                                <a target="_blank" href="https://plus.google.com/share?url=http%3A//www.wowthemes.net">
                                    <svg className="svgIcon-use" width="29" height="29" viewBox="0 0 29 29">
                                        <path d="M16.6 10.04c-.2 0-.95-.04-1.63-.04-1.55 0-2.53.33-3.38.7-.53.2-1 .46-1.46.8a5.63 5.63 0 0 0-2.13 4.34 5.637 5.637 0 0 0 3.77 5.38c.48.16.98.22 1.53.22 1.4 0 2.43-.46 3.17-1.28.67-.75.96-1.8.93-2.85-.04-1.12-.4-2.05-1.1-2.75-.73-.72-1.8-1-3.12-1-.37 0-.76.02-1.15.04-.2.03-.5.1-.67.15-.1.03-.3.1-.47.1l.02-.05-.35 1.6c.04.03.07.05.1.05.17 0 .4-.06.6-.1.48-.1.96-.22 1.44-.25h.22c1.1 0 1.93.2 2.4.6.3.28.5.65.58 1.1.06.33.1.66.1.98 0 1-.33 1.83-.93 2.46-.64.67-1.57.98-2.8.98-.42 0-.82-.05-1.2-.15a4.12 4.12 0 0 1-1.94-1.16c-.35-.36-.62-.8-.8-1.3-.12-.4-.18-.83-.18-1.3v-.1c0-.42.06-.85.18-1.25a3.82 3.82 0 0 1 .93-1.64 3.7 3.7 0 0 1 1.4-.97c.56-.2 1.2-.3 1.92-.3.67 0 1.26.08 1.78.25.4.13.77.3 1.1.52.08.05.15.1.22.16l.1.06.12-.06c.2-.12.4-.26.62-.4.3-.22.6-.45.85-.7-.02-.04-.26-.22-.35-.3a6.48 6.48 0 0 0-2.14-1.1c-.43-.1-.96-.17-1.63-.17m5.66 3.1h-.83v.83h-.42v-.83h-.82v-.42h.82v-.82h.42v.82h.83v.42M6.2 14.72c-.17 0-.34.06-.52.16a3.96 3.96 0 0 0-1.45 1.45 3.71 3.71 0 0 0 0 4.06c.37.58.85 1.03 1.45 1.46.43.26.83.36 1.3.36.15 0 .28 0 .38-.02-.43-.7-.68-1.54-.68-2.46 0-1.62.68-2.95 1.8-3.84a2.4 2.4 0 0 0-2.28-1.16M4.4 5.53c.18 0 .34.06.52.16.6.36 1.1.87 1.44 1.46.28.43.37.83.37 1.3 0 .88-.25 1.72-.68 2.44a.95.95 0 0 1-.38-.04c-.5-.1-.98-.37-1.46-.85-.44-.46-.72-.95-.88-1.5a.95.95 0 0 1-.04-.4c0-.18.05-.37.16-.56.37-.58.85-1.1 1.45-1.44.17-.1.35-.14.52-.14"></path>
                                    </svg>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                {page.map( post=> (
                <div className="col-md-10 col-xs-12">
                    <h2>{post.title}</h2>
                    <div dangerouslySetInnerHTML={createMarkup(post.content)} />
                </div>
                  ))}
            </div>
        </div>
    );
};

export default About;
