import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import DOMPurify from 'dompurify';

const Blog = () => {
    const [posts, setPosts] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [fposts, setfPosts] = useState([]);

    useEffect(() => {
        axios.get('https://lazyblogger.in/api/index.php/')
            .then(response => {
                if (Array.isArray(response.data)) {
                    setPosts(response.data);
                } else {
                    setPosts([]); // Default to empty array if response is not an array
                }
            })
            .catch(error => {
                console.error('Error fetching posts:', error);
                setPosts([]); // Set empty array on error to prevent filter() issues
            });
    }, []);

    useEffect(() => {
        axios.get('https://lazyblogger.in/api/index.php/api/featured/')
            .then(response => {
                if (Array.isArray(response.data)) {
                    setfPosts(response.data);
                } else {
                    setfPosts([]); // Default to empty array if response is not an array
                }
            })
            .catch(error => {
                console.error('Error fetching featured posts:', error);
                setfPosts([]);
            });
    }, []);

    const createMarkup = (html) => ({
        __html: DOMPurify.sanitize(html)
    });

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    // Ensure filtering is done on a valid array
    const filteredPosts = Array.isArray(posts) ? posts.filter(post =>
        post.article_title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        post.teaser_text?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        post.category_name?.toLowerCase().includes(searchTerm.toLowerCase())
    ) : [];

    const featuredfilteredPosts = Array.isArray(fposts) ? fposts.filter(fpost =>
        fpost.article_title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        fpost.teaser_text?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        fpost.category_name?.toLowerCase().includes(searchTerm.toLowerCase())
    ) : [];

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12 col-xs-12">
                    <input
                        className='search-input'
                        type="search"
                        placeholder="Search posts..."
                        value={searchTerm}
                        onChange={handleSearch}
                    />
                </div>
            </div>

            <section className="featured-posts">
                <div className="section-title">
                    <h2><span>Featured</span></h2>
                </div>
                <div className='card-columns listfeaturedtag'>
                    {featuredfilteredPosts.map(fpost => (
                        <div className='card' key={fpost.article_id}>
                            <div className="row">
                                <div className="col-md-5 wrapthumbnail">
                                    <div className="thumbnail" style={{ backgroundImage: `url(https://lazyblogger.in/images/${fpost.image_front})` }}>
                                    </div>
                                </div>
                                <div className="col-md-7">
                                    <div className="card-block">
                                        <Link to={`/${fpost.slug}`} title="Read Story">
                                            <h2 className="card-title">{fpost.article_title}</h2>
                                        </Link>
                                        <h4 className="card-text">
                                            <div dangerouslySetInnerHTML={createMarkup(fpost.teaser_text)} />
                                        </h4>
                                        <div className="metafooter">
                                            <div className="wrapfooter">
                                                <span className="author-meta">
                                                    <span className="post-name">{fpost.category_name}</span><br />
                                                </span>
                                                <span className="post-read-more">
                                                    <Link to={`/${fpost.slug}`} title="Read Story">
                                                        <svg className="svgIcon-use" width="25" height="25" viewBox="0 0 25 25">
                                                            <path d="M19 6c0-1.1-.9-2-2-2H8c-1.1 0-2 .9-2 2v14.66h.012c.01.103.045.204.12.285a.5.5 0 0 0 .706.03L12.5 16.85l5.662 4.126a.508.508 0 0 0 .708-.03.5.5 0 0 0 .118-.285H19V6zm-6.838 9.97L7 19.636V6c0-.55.45-1 1-1h9c.55 0 1 .45 1 1v13.637l-5.162-3.668a.49.49 0 0 0-.676 0z" fillRule="evenodd"></path>
                                                        </svg>
                                                    </Link>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </section>

            <section className="recent-posts">
                <div className="section-title">
                    <h2><span>All Stories</span></h2>
                </div>
                <div className="card-columns listrecent">
                    {filteredPosts.map(post => (
                        <div className='card card-cstm' key={post.article_id}>
                            <img className="img-all-blog" src={`https://lazyblogger.in/images/${post.image_main}`} alt="" />
                            <div className="card-block">
                                <h2 className="card-title crd-tit-cst">{post.article_title}</h2>
                                <h4 className="card-text">
                                    <div dangerouslySetInnerHTML={createMarkup(post.teaser_text)} />
                                </h4>
                                <div className="metafooter">
                                    <div className="wrapfooter">
                                        <span className="author-meta">
                                            <span className="post-name">{post.category_name}</span><br />
                                        </span>
                                        <span className="post-read-more">
                                            <Link to={`/${post.article_id}`} title="Read Story">
                                                <svg className="svgIcon-use" width="25" height="25" viewBox="0 0 25 25">
                                                    <path d="M19 6c0-1.1-.9-2-2-2H8c-1.1 0-2 .9-2 2v14.66h.012c.01.103.045.204.12.285a.5.5 0 0 0 .706.03L12.5 16.85l5.662 4.126a.508.508 0 0 0 .708-.03.5.5 0 0 0 .118-.285H19V6zm-6.838 9.97L7 19.636V6c0-.55.45-1 1-1h9c.55 0 1 .45 1 1v13.637l-5.162-3.668a.49.49 0 0 0-.676 0z" fillRule="evenodd"></path>
                                                </svg>
                                            </Link>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </section>

            <div className="footer">
                <p className="pull-left">
                    Copyright © 2024 Your Website Name
                </p>
            </div>
        </div>
    );
};

export default Blog;
