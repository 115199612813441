// src/NavBar.js
import React from 'react';
import { Link } from 'react-router-dom';
//import './NavBar.css'; // Create this file for NavBar specific styles

const NavBar = () => {
    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container nav-cont">
                <Link className="navbar-brand" to="/"> 
                <img className="img-all-blog" src="/lazyblogger-logo.png" alt="Site Logo" width={160}/>
                <p className="lead-sub">A Little Nap, A Little Writing</p>
                </Link>
               
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item">
                            <Link className="nav-link" to="/">Home</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/about">About</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default NavBar;
