import React, { useState, useEffect } from 'react';
import axios from 'axios';

const CommentBox = ({ postId }) => {
  const [comments, setComments] = useState([]);
  const [author, setAuthor] = useState('');
  const [content, setContent] = useState('');
  const [submitSuccess, setSubmitSuccess] = useState(false);

  useEffect(() => {
    axios.get(`https://lazyblogger.in/api/index.php/comments/${postId}`)
      .then(response => {
        setComments(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the comments!', error);
      });
  }, [postId]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const commentData = {
      post_id: postId,
      author,
      content
    };
    console.log('Submitting comment data:', commentData); // Debugging log
    axios.post('https://lazyblogger.in/api/index.php/comments/add', commentData)
      .then(response => {
        console.log('Response from server:', response.data); // Debugging log
        setComments([...comments, { id: response.data.id, ...commentData }]);
        setAuthor('');
        setContent('');
        setSubmitSuccess(true);
        setTimeout(() => setSubmitSuccess(false), 3000); // Hide the success message after 3 seconds
      })
      .catch(error => {
        console.error('There was an error posting the comment!', error);
      });
  };

  return (
    <div className="comment-box">
      <h3>Comments</h3>
      <div className="list-group mb-4">
        {comments.map(comment => (
          <div key={comment.id} className="list-group-item comment-align">
            <div className="comment-header">
              <strong className="comment-author">{comment.author}</strong>
              <span className="comment-date">{comment.pubdate}</span>
            </div>
            <div className="comment-content">{comment.content}</div>
          </div>
        ))}
      </div>
      <form onSubmit={handleSubmit} className="comment-form">
        <input 
          type="text"
          value={author}
          onChange={(e) => setAuthor(e.target.value)}
          placeholder="Your name"
          required
        />
        <textarea
          value={content}
          onChange={(e) => setContent(e.target.value)}
          placeholder="Your comment"
          required
        />
        <button type="submit">Add Comment</button>
      </form>
      {submitSuccess && <div className="success-message">Comment submitted successfully!</div>}
    </div>
  );
};

export default CommentBox;