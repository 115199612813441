import React from 'react';
import { Link, useLocation } from 'react-router-dom';

// Example function to convert path segments into human-readable titles
const formatPathname = (pathname) => {
    // Replace hyphens with spaces and capitalize words
    return pathname
        .replace(/-/g, ' ')
        .replace(/\b\w/g, char => char.toUpperCase());
};

const Breadcrumb = () => {
    const location = useLocation();
    const { pathname } = location;
    const pathnames = pathname.split('/').filter(x => x);

    // If the pathname is '/', do not render the breadcrumb
    if (pathname === '/') {
        return null;
    }

    return (
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb bc-bg">
                <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                </li>
                {pathnames.length > 0 && pathnames.map((path, index) => {
                    const to = `/${pathnames.slice(0, index + 1).join('/')}`;
                    return (
                        <li
                            key={to}
                            className={`breadcrumb-item ${index === pathnames.length - 1 ? 'active' : ''}`}
                        >
                            {index === pathnames.length - 1 ? (
                                formatPathname(path)
                            ) : (
                                <Link to={to}>{formatPathname(path)}</Link>
                            )}
                        </li>
                    );
                })}
            </ol>
        </nav>
    );
};

export default Breadcrumb;
