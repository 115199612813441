import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Blog from './Blog';
import PageById from './PageById'; // Create this component
import Breadcrumb from './Breadcrumb'; // Import the Breadcrumb component
import About from './About'; // Import the Breadcrumb component
import NavBar from './NavBar'
import './mediumish.css';
import './bootstrap.min.css';
import './App.css';

const App = () => {
    return (
        <Router>
            <div>
            <NavBar /> 
            <Breadcrumb />
            <Routes>
                <Route path="/" element={<Blog />} />
                {/*<Route path="/blog" element={<Blog />} />*/}
                <Route path="/:slug" element={<PageById />} />
                <Route path="/about" element={<About />} />
            </Routes>
            </div>
        </Router>
    );
};

export default App;